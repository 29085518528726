<template>
    <admin-wrapper>
        <app-loader :value="$apollo.loading"/>
        <onboarding-dialog v-model="onboardingDialog"></onboarding-dialog>
        <v-card flat v-if="store && store.connectedAccount">
            <v-container>
                <nice-header>
                    Billing configuration
                    <template v-slot:extra>
                        <v-spacer></v-spacer>
                        <v-btn v-if="store.connectedAccount && store.connectedAccount.charges_enabled && store.connectedAccount.payouts_enabled" :href="store.accountLink" target="_blank" link rounded outlined color="primary">
                            Edit billing configuration in Stripe
                        </v-btn>
                        <v-btn v-else :href="store.accountLink" target="_blank" link rounded outlined color="primary">
                            Complete configuration
                        </v-btn>
                    </template>
                </nice-header>
                <v-alert type="success" outlined
                         v-if="store.connectedAccount.charges_enabled && store.connectedAccount.payouts_enabled">
                    Payments and payouts have been successfully enabled for your account
                </v-alert>
                <v-alert v-else type="warning" outlined>
                    Payments and payouts have not yet been enabled for your account - use the button above to complete
                    the process
                </v-alert>
            </v-container>

            <v-container>
                <v-data-table
                        :items="store.transactions"
                        :headers="headers"
                >
                    <template v-slot:item.created="{ item }">
                        {{ item.created | niceTime }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                        {{ item.amount | formatMoney}}
                    </template>
                    <template v-slot:item.application_fee="{ item }">
                        <div v-if="item.fee_details && !!getFee(item.fee_details, 'application_fee')">
                        {{ getFee(item.fee_details, 'application_fee').amount | formatMoney }} {{ getFee(item.fee_details,
                        'application_fee').currency.toUpperCase() }}
                        </div>
                    </template>
                    <template v-slot:item.stripe_fee="{ item }">
                        <div v-if="item.fee_details && !!getFee(item.fee_details, 'stripe_fee')">
                        {{ getFee(item.fee_details, 'stripe_fee').amount | formatMoney }} {{ getFee(item.fee_details,
                        'stripe_fee').currency.toUpperCase() }}
                        </div>
                    </template>
                </v-data-table>

            </v-container>
        </v-card>

    </admin-wrapper>
</template>

<script>
    import { mapState } from 'vuex'
    import { stores } from '@/graphql'
    import moment from 'moment'

    const formatMoney = function (number) {
        var decPlaces = 2;
        const decSep = '.';
        const thouSep = ',';

        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    };

    export default {
        filters: {
            niceTime(value) {
                return moment.unix(value).calendar()
            },
            formatMoney(number) {
                return formatMoney(number/100)
            }
        },
        apollo: { stores },
        updated () {
            if (!this.$apollo.queries.stores.loading && !this.store.connectedAccount) {
                this.onboardingDialog = true
            }
        },
        data: () => ({
            onboardingDialog: false,
            headers: [
                {text: 'Created', value: 'created'},
                {text: 'Status', value: 'status'},
                {text: 'Amount', value: 'amount'},
                {text: 'Rentify fee', value: 'application_fee', sortable: false},
                {text: 'Stripe fee', value: 'stripe_fee', sortable: false},
                {text: 'Type', value: 'type'},
            ],
        }),
        methods: {
            getFee(fees, type) {
                return fees.find(fee => fee.type === type)
            },
            asMoney (value) {
                return formatMoney(value/100)
            }
        },
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.stores) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            }
        },
        watch: {
            store (store) {
                if (!store.connectedAccount && !this.$apollo.loading) {
                    this.onboardingDialog = true
                }
            }
        }
    }
</script>
