var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-wrapper',[_c('app-loader',{attrs:{"value":_vm.$apollo.loading}}),_c('onboarding-dialog',{model:{value:(_vm.onboardingDialog),callback:function ($$v) {_vm.onboardingDialog=$$v},expression:"onboardingDialog"}}),(_vm.store && _vm.store.connectedAccount)?_c('v-card',{attrs:{"flat":""}},[_c('v-container',[_c('nice-header',{scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('v-spacer'),(_vm.store.connectedAccount && _vm.store.connectedAccount.charges_enabled && _vm.store.connectedAccount.payouts_enabled)?_c('v-btn',{attrs:{"href":_vm.store.accountLink,"target":"_blank","link":"","rounded":"","outlined":"","color":"primary"}},[_vm._v(" Edit billing configuration in Stripe ")]):_c('v-btn',{attrs:{"href":_vm.store.accountLink,"target":"_blank","link":"","rounded":"","outlined":"","color":"primary"}},[_vm._v(" Complete configuration ")])]},proxy:true}],null,false,2766916564)},[_vm._v(" Billing configuration ")]),(_vm.store.connectedAccount.charges_enabled && _vm.store.connectedAccount.payouts_enabled)?_c('v-alert',{attrs:{"type":"success","outlined":""}},[_vm._v(" Payments and payouts have been successfully enabled for your account ")]):_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" Payments and payouts have not yet been enabled for your account - use the button above to complete the process ")])],1),_c('v-container',[_c('v-data-table',{attrs:{"items":_vm.store.transactions,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceTime")(item.created))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")]}},{key:"item.application_fee",fn:function(ref){
var item = ref.item;
return [(item.fee_details && !!_vm.getFee(item.fee_details, 'application_fee'))?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.getFee(item.fee_details, 'application_fee').amount))+" "+_vm._s(_vm.getFee(item.fee_details, 'application_fee').currency.toUpperCase())+" ")]):_vm._e()]}},{key:"item.stripe_fee",fn:function(ref){
var item = ref.item;
return [(item.fee_details && !!_vm.getFee(item.fee_details, 'stripe_fee'))?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.getFee(item.fee_details, 'stripe_fee').amount))+" "+_vm._s(_vm.getFee(item.fee_details, 'stripe_fee').currency.toUpperCase())+" ")]):_vm._e()]}}],null,false,2270585614)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }